import React from "react";
// import logo from "../../images/logo.png";
import logo from "../../images/logo1.png";

export default function Header() {
  return (
    <>
      <div className="bg-white 2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[50px] sm:px-[30px] px-[20px] my-5 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="logo" className="h-14" />
          <h1 className="font-extrabold text-xl hidden md:flex md:ps-5">
            Renko
          </h1>
        </div>
        <div className="flex gap-4">
          <button
            className="rounded-lg border-[#D1D5DB] justify-center items-center border-2 bg-white w-36 hidden md:flex py-3 px-7" //hover:bg-slate-950 hover:border-slate-950 hover:text-white
            type="button"
          >
            Start Free
          </button>
          <button
            className="rounded-lg border-[#0E0923] border-2 justify-center items-center bg-[#0E0923] text-white py-3 px-7 w-36" //hover:bg-white hover:text-black hover:border-gray-400
            type="button"
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
}
