import React from "react";
import Header from "../pages/Header/Header";
import Footer from "../pages/Footer/Footer";

const MainLayouts = ({ children }) => {
  return (
    <div>
      <Header />
      <main className="">{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayouts;
