import React from "react";
import logo from "../../images/logo1.png";
import tiktok from "../../images/tiktok.png";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import linkdin from "../../images/linkdin.png";
import youtube from "../../images/youtube.png";
import twitter from "../../images/x.png";

export default function Header() {
  return (
    <>
      <div className="footer py-10 2xl:px-[80px] xl:px-[60px] md:px-[50px] lg:px-[50px] sm:px-[30px] px-[20px]">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-6 items-center gap-2 md:pt-0 md:pb-0 pt-5 pb-10 flex">
            <img src={logo} alt="logo" className="w-16" />
            <h1 className="text-white font-bold text-xl">Renko</h1>
          </div>
          <div className="col-span-12 md:col-span-6 justify-center flex md:justify-end items-center gap-2">
            <img src={tiktok} alt="tiktok" className="size-6 md:size-7"></img>
            <img
              src={facebook}
              alt="facebook"
              className="size-6 md:size-7"
            ></img>
            <img
              src={instagram}
              alt="instagram"
              className="size-6 md:size-7"
            ></img>
            <img src={linkdin} alt="linkdin" className="size-6 md:size-7"></img>
            <img src={youtube} alt="youtube" className="size-6 md:size-7"></img>
            <img src={twitter} alt="twitter" className="size-6 md:size-7"></img>
          </div>
        </div>
        <div className="grid grid-cols-12 items-center md:mt-14">
          <div className="col-span-12 md:col-span-6 md:text-start text-center items-center gap-2 md:pt-0 md:pb-0 py-5">
            <h1 className="text-white text-sm font-[500]">
              © 2024 Renko. All Rights Reserved.
            </h1>
          </div>
          <div className="col-span-12 md:col-span-6 justify-center flex md:justify-end items-center md:gap-4 gap-2 text-white ">
            <p className="text-xs">Terms & Conditions</p>
            <p className="text-xs">Privacy Policy</p>
            <p className="text-xs">California Privacy Notice</p>
          </div>
        </div>
      </div>
    </>
  );
}
